import settingsParams from '../../settingsParams';
import { AsyncComponentDataStatus } from '../../state/types';
import { ViewModelSubscription } from '../initViewModelSubscriptions';
import { WidgetViewModelFactory } from '../viewModel';

export type LoadMoreViewModel = {
  isLoading: boolean;
  buttonText: string;
  hasMore?: boolean;
};

export const createLoadMoreViewModel: WidgetViewModelFactory<
  LoadMoreViewModel
> = ({ state: { slotsMetaData }, flowAPI: { settings } }) => {
  const buttonText = settings.get(settingsParams.loadMoreButton);
  if (slotsMetaData.status === AsyncComponentDataStatus.LOADING) {
    return {
      isLoading: true,
      buttonText,
    };
  }

  if (slotsMetaData.status === AsyncComponentDataStatus.ERROR) {
    return {
      isLoading: false,
      buttonText,
    };
  }

  return {
    isLoading: false,
    hasMore: !!slotsMetaData.data?.hasNext,
    buttonText,
  };
};

export const subscribeToLoadMoreViewModel: ViewModelSubscription = ({
  subscribe,
  setViewModel,
  getState,
  flowAPI,
}) => {
  subscribe(
    ({ slotsMetaData }) => ({
      slotsMetaDataStatus: slotsMetaData.status,
    }),
    () => {
      const loadMoreViewModel = createLoadMoreViewModel({
        flowAPI,
        state: { ...getState() },
      });
      setViewModel({ loadMoreViewModel });
    },
  );
};
