import { ViewModelFactoryArgs } from '../state/types';
import {
  AgendaViewModel,
  createAgendaViewModel,
} from './agendaViewModel/agendaViewModel';
import {
  createTitleViewModel,
  TitleViewModel,
} from './titleViewModel/titleViewModel';
import {
  createFiltersViewModel,
  FiltersViewModel,
} from './filtersViewModel/filtersViewModel';
import {
  createDatePickerViewModel,
  DatePickerViewModel,
} from './datePickerViewModel/datePickerViewModel';
import { TimeZoneViewModel } from './timeZoneViewModel/timeZoneViewModel';
import {
  createLoadMoreViewModel,
  LoadMoreViewModel,
} from './loadMoreViewModel/loadMoreViewModel';

export type WidgetViewModel = {
  isInitDataLoading: boolean;
  titleViewModel: TitleViewModel;
  datePickerViewModel: DatePickerViewModel;
  filtersViewModel?: FiltersViewModel;
  timeZoneViewModel?: TimeZoneViewModel;
  agendaViewModel?: AgendaViewModel;
  loadMoreViewModel?: LoadMoreViewModel;
};

export type WidgetViewModelFactory<ViewModel> = (
  args: ViewModelFactoryArgs,
) => ViewModel;

export const createDailyAgendaViewModel = (
  viewModelFactoryArgs: ViewModelFactoryArgs,
): WidgetViewModel => {
  return {
    isInitDataLoading: true,
    filtersViewModel: createFiltersViewModel(viewModelFactoryArgs),
    titleViewModel: createTitleViewModel(viewModelFactoryArgs),
    agendaViewModel: createAgendaViewModel(viewModelFactoryArgs),
    datePickerViewModel: createDatePickerViewModel(viewModelFactoryArgs),
    loadMoreViewModel: createLoadMoreViewModel(viewModelFactoryArgs),
  };
};
