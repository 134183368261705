import { AsyncComponentDataStatus } from '../../state/types';
import { ViewModelSubscription } from '../initViewModelSubscriptions';
import { WidgetViewModelFactory } from '../viewModel';

export type InitialLoaderViewModel = boolean;

export const createInitialLoaderViewModel: WidgetViewModelFactory<
  InitialLoaderViewModel
> = ({
  state: { services, slots, staffMembers, locations, availableDays },
}) => {
  const isInitDataLoading =
    services.status === AsyncComponentDataStatus.LOADING ||
    slots.status === AsyncComponentDataStatus.LOADING ||
    staffMembers.status === AsyncComponentDataStatus.LOADING ||
    locations.status === AsyncComponentDataStatus.LOADING ||
    availableDays.status === AsyncComponentDataStatus.LOADING;

  return isInitDataLoading;
};

export const subscribeToInitialLoaderViewModel: ViewModelSubscription = ({
  subscribe,
  setViewModel,
  getState,
  flowAPI,
}) => {
  const unSubscribe = subscribe(
    ({ services, slots, staffMembers, locations, availableDays }) => ({
      servicesStatus: services.status,
      slotsStatus: slots.status,
      staffMembersStatus: staffMembers.status,
      locationsStatus: locations.status,
      availableDaysStatus: availableDays.status,
    }),
    () => {
      const isInitDataLoading = createInitialLoaderViewModel({
        flowAPI,
        state: { ...getState() },
      });
      if (!isInitDataLoading) {
        setViewModel({ isInitDataLoading });
        unSubscribe();
      }
    },
  );
};
