import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GetState, SetState } from '../state/createState';
import { createOnSlotClickAction } from './onSlotClick/onSlotClick';
import { createOnFiltersChangeAction } from './onFiltersChange/onFiltersChange';
import { Filter } from '../../../types/types';
import { createOnTimeZoneChangeAction } from './onTimeZoneChange.ts/onTimeZoneChange';
import { createOnWeekChangeAction } from './onWeekChange/onWeekChange';
import { createOnLoadMoreAction } from './onLoadMore/onLoadMore';

export type DailyAgendaActions = {
  onFiltersChange: (newSelectedFilters: Filter[]) => Promise<void>;
  onSlotClick: (slotId: string) => Promise<void>;
  onTimeZoneChange: (timezoneId: string) => Promise<void>;
  onWeekChange: (range: { from: string; to: string }) => Promise<void>;
  onLoadMore: () => Promise<void>;
};

export type DailyAgendaActionFactory<ActionFN extends Function> = (args: {
  setState: SetState;
  getState: GetState;
  flowAPI: ControllerFlowAPI;
}) => ActionFN;

export const createDailyAgendaActions = (args: {
  setState: SetState;
  getState: GetState;
  flowAPI: ControllerFlowAPI;
}): DailyAgendaActions => ({
  onLoadMore: createOnLoadMoreAction(args),
  onWeekChange: createOnWeekChangeAction(args),
  onSlotClick: createOnSlotClickAction(args),
  onFiltersChange: createOnFiltersChangeAction(args),
  onTimeZoneChange: createOnTimeZoneChangeAction(args),
});
