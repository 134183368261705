import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../components/DailyAgenda/settingsParams';
import { QueryFilters } from '../types';
import { Filter, FilterType } from '../../../types/types';

export const getQueryFiltersFromSettings = (
  settings: ControllerFlowAPI['settings'],
): QueryFilters => {
  const serviceIds = settings.get(settingsParams.selectedServices) || [];
  const businessLocationIds =
    settings.get(settingsParams.selectedLocations) || [];
  const categories = settings.get(settingsParams.selectedCategories) || [];

  return {
    serviceIds,
    businessLocationIds,
    categories,
    staffMemberIds: [],
  };
};

export const mergeQeuryFilters = (
  qf1: QueryFilters = {},
  qf2: QueryFilters = {},
) => {
  return {
    serviceIds: mergeIds(qf1.serviceIds, qf2.serviceIds),
    businessLocationIds: mergeIds(
      qf1.businessLocationIds,
      qf2.businessLocationIds,
    ),
    categories: mergeIds(qf1.categories, qf2.categories),
    staffMemberIds: mergeIds(qf1.staffMemberIds, qf2.staffMemberIds),
  };
};

const mergeIds = (arr1?: string[], arr2?: string[]) => [
  ...new Set([...(arr1 || []), ...(arr2 || [])]),
];

export const getQueryFiltresIntersection = (
  qf1: QueryFilters = {},
  qf2: QueryFilters = {},
): QueryFilters => {
  if (qf1 && !qf2) {
    return qf1;
  }
  if (!qf1 && qf2) {
    return qf2;
  }

  return {
    serviceIds: getIntersection(qf1.serviceIds, qf2.serviceIds),
    businessLocationIds: getIntersection(
      qf1.businessLocationIds,
      qf2.businessLocationIds,
    ),
    categories: getIntersection(qf1.categories, qf2.categories),
    staffMemberIds: getIntersection(qf1.staffMemberIds, qf2.staffMemberIds),
  };
};

const getIntersection = (arr1?: string[], arr2?: string[]) => {
  if (!arr1 || arr1.length === 0) {
    return arr2;
  }
  if (!arr2 || arr2.length === 0) {
    return arr1;
  }
  return arr1.filter((item) => arr2.includes(item));
};

export const convertSelectedFiltersToQueryFilters = (
  selectedFilters: Filter[],
): QueryFilters => {
  const queryFilters: QueryFilters = {
    serviceIds: [],
    businessLocationIds: [],
    staffMemberIds: [],
  };

  selectedFilters.forEach((filter) => {
    filter.options.forEach((option) => {
      if (option.selected) {
        switch (filter.type) {
          case FilterType.SERVICE:
            queryFilters.serviceIds!.push(option.id);
            break;
          case FilterType.LOCATION:
            queryFilters.businessLocationIds!.push(option.id);
            break;
          case FilterType.STAFF_MEMBER:
            queryFilters.staffMemberIds!.push(option.id);
            break;
          default:
            break;
        }
      }
    });
  });

  return queryFilters;
};
