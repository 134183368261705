import { getLocalDateFromDateTime } from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { listEventTimeSlots } from '../../../../adapters';
import { AsyncComponentDataStatus } from '../../state/types';
import { DailyAgendaActionFactory, DailyAgendaActions } from '../actions';
import debounce from 'lodash.debounce';

export const createOnWeekChangeAction: DailyAgendaActionFactory<
  DailyAgendaActions['onWeekChange']
> = ({ setState, flowAPI }) => {
  const onWeekChangeAction: DailyAgendaActions['onWeekChange'] = async ({
    from,
    to,
  }) => {
    setState({
      availableDays: { status: AsyncComponentDataStatus.LOADING },
    });

    try {
      const { timeSlots } = await listEventTimeSlots({
        flowAPI,
        range: {
          from: new Date(from),
          to: new Date(to),
        },
        timeSlotsPerDay: 1,
        limit: 7,
      });

      if (!timeSlots || timeSlots.length === 0) {
        setState({
          availableDays: {
            status: AsyncComponentDataStatus.ERROR,
            data: 'No available days found',
          },
        });
        return;
      }

      setState({
        availableDays: {
          status: AsyncComponentDataStatus.IDLE,
          data: timeSlots.map((slot) =>
            getLocalDateFromDateTime(new Date(slot.localStartDate!)),
          ),
        },
      });
    } catch (error) {
      setState({
        availableDays: {
          status: AsyncComponentDataStatus.ERROR,
          data: 'Error fetching available days',
        },
      });
      console.error('Error fetching week slots', error);
    }
  };
  const debouncedOnFiltersChange = debounce(async (range) => {
    await onWeekChangeAction(range);
  }, 250);

  return (range) => debouncedOnFiltersChange(range) as Promise<void>;
};
