import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { SetState } from '../../../components/DailyAgenda/state/createState';
import { listEventTimeSlots } from '../../api/listEventTimeSlots/listEventTimeSlots';
import { AsyncComponentDataStatus } from '../../../components/DailyAgenda/state/types';
import { getLocalDateFromDateTime } from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import type { IHttpError } from '@wix/http-client';

export const initAvailableDays = async ({
  flowAPI,
  setState,
}: {
  flowAPI: ControllerFlowAPI;
  setState: SetState;
}) => {
  try {
    const { timeSlots } = await listEventTimeSlots({
      flowAPI,
      timeSlotsPerDay: 1,
      limit: 7,
    });

    if (!timeSlots || timeSlots.length === 0) {
      setState({
        availableDays: {
          status: AsyncComponentDataStatus.ERROR,
          data: 'No available days found',
        },
      });
      return;
    }

    const availableDays = timeSlots.map((slot) =>
      getLocalDateFromDateTime(new Date(slot.localStartDate!)),
    );

    setState({
      availableDays: {
        status: AsyncComponentDataStatus.IDLE,
        data: availableDays,
      },
    });
  } catch (error) {
    setState({
      availableDays: {
        status: AsyncComponentDataStatus.ERROR,
        data: (error as IHttpError).response?.data,
      },
    });
    console.error('Error initializing available days:', error);
  }
};
