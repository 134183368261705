import { TimeSlot } from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import { Service } from '@wix/ambassador-bookings-services-v2-service/types';
import {
  formatRfcTimeStringToTimeSlotView,
  fromLocalDateTimeToRfc,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { getDurationText } from '@wix/bookings-catalog-calendar-viewer-utils/duration';
import { ViewModelFactoryArgs } from '../../../state/types';
import {
  getPaymentDescription,
  isServicePendingApprovalFlow,
} from '@wix/bookings-calendar-catalog-viewer-mapper';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  isSlotLocked,
  isSlotWithBookingsPolicyViolation,
  isFullSlot,
  isTooEarlyToBookSlot,
  isTooLateToBookSlot,
  isSlotNoBookFlow,
  isSlotWithOpenWaitingList,
} from '../../../../../utils/slotAvailability/slotAvailability';
import settingsParams from '../../../settingsParams';
import { StaffMember } from '@wix/ambassador-bookings-staff-v1-staff-member/types';

export type AgendaItemViewModel = {
  serviceName?: string;
  serviceImage?: { src: string; alt?: string };
  image?: any;
  startTime?: string;
  duration?: string;
  staffName?: string;
  staffImage?: string;
  location?: string;
  spots?: string;
  price?: string;
  buttonText?: string;
  disabled?: boolean;
  slotId: string;
};

export const createAgendaItemViewModel = ({
  slot,
  state: { languageSettings },
  flowAPI,
  service,
  staffMember,
}: {
  slot: TimeSlot;
  service: Service;
  staffMember?: StaffMember;
} & ViewModelFactoryArgs): AgendaItemViewModel => {
  const {
    settings,
    translations: { t },
  } = flowAPI;
  const rfcStartTime = fromLocalDateTimeToRfc(slot.localStartDate!);
  const rfcEndTime = fromLocalDateTimeToRfc(slot.localEndDate!);
  const location = service?.locations?.find(
    ({ id }) => slot.location?.id === id,
  )?.business?.name;

  const formattedTime = formatRfcTimeStringToTimeSlotView(rfcStartTime);

  const serviceName = service?.name!;
  // TODO - dummy data

  const duration = getDurationText({
    dateRegionalSettingsLocale: languageSettings.dateRegionalSettingsLocale,
    rfcStartTime,
    rfcEndTime,
    t,
  }).durationText;

  const price = getSlotPrice({
    service: service!,
    regionalSettingsLocale: languageSettings.regionalSettingsLocale!,
    t,
  });

  const spotsLeft = getSpotsLeft({ slot, flowAPI });
  let buttonText: string;
  let disabled: boolean = false;
  const policyViolation = getPolicyViolationText({
    flowAPI,
    slot,
  });

  if (policyViolation) {
    buttonText = policyViolation;
    disabled = !isSlotNoBookFlow(slot);
  } else if (isServicePendingApprovalFlow(service!)) {
    buttonText = settings.get(settingsParams.pendingApprovalButton);
  } else if (isSlotWithOpenWaitingList(slot)) {
    buttonText = settings.get(settingsParams.joinWaitlistButton);
  } else if (isFullSlot(slot)) {
    buttonText = settings.get(settingsParams.noSpotsLeft);
  } else {
    buttonText = settings.get(settingsParams.bookButtonText);
  }

  return {
    slotId: slot.eventInfo?.eventId!,
    startTime: formattedTime,
    serviceName,
    location,
    spots: spotsLeft,
    duration,
    price,
    staffName: staffMember?.name!,
    buttonText,
    disabled,
    serviceImage: service?.media?.mainMedia?.image
      ? {
          src: service?.media?.mainMedia?.image?.url!,
          alt: service?.media?.mainMedia?.image?.altText!,
        }
      : undefined,
    staffImage: staffMember?.mainMedia?.image?.url,
  };
};

const getSpotsLeft = ({
  slot,
  flowAPI: {
    settings,
    translations: { t },
  },
}: {
  slot: TimeSlot;
  flowAPI: ControllerFlowAPI;
}) => {
  const openSpots = slot.remainingCapacity!;

  if (isSlotWithBookingsPolicyViolation(slot)) {
    return;
  }

  if (isSlotLocked(slot)) {
    return;
  }

  if (isFullSlot(slot)) {
    return;
  }

  const customFormat = settings.get(settingsParams.spotsLeftFormat);
  if (customFormat) {
    return t('app.agenda-slot.spots-left.custom-label', {
      openSpots,
      spotsLeftFormat: customFormat,
    });
  }

  return t('app.agenda-slot.spots-left.label', {
    openSpots,
  });
};

const getSlotPrice = ({
  t,
  service,
  regionalSettingsLocale,
}: {
  service: Service;
  regionalSettingsLocale: string;
  t: any;
}): string => {
  const payment = service.payment!;

  const getPaymentDescriptionArgs: Parameters<typeof getPaymentDescription>[0] =
    {
      payment,
      regionalSettingsLocale,
      paymentFormatters: {
        variedPrice: ({ minPrice }) =>
          t('payment.from-price', { price: minPrice }),
      },
    };

  return getPaymentDescription(getPaymentDescriptionArgs);
};

const getPolicyViolationText = ({
  flowAPI: { settings },
  slot,
}: {
  flowAPI: ControllerFlowAPI;
  slot: TimeSlot;
}): string | undefined => {
  if (isSlotNoBookFlow(slot)) {
    return settings.get(settingsParams.bookOnlineDisabledIndication);
  }

  if (isTooEarlyToBookSlot(slot)) {
    return settings.get(settingsParams.toEarlyToBookIndication);
  }

  if (isTooLateToBookSlot(slot)) {
    return settings.get(settingsParams.toLateToBookIndication);
  }
};
