import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Filter } from '../../../types/types';
import {
  ListEventTimeSlotsResponse,
  TimeSlot,
} from '@wix/ambassador-bookings-availability-v2-time-slot/types';
import {
  Location,
  Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { LanguageSettings } from '@wix/bookings-catalog-calendar-viewer-utils';
import { StaffMember } from '@wix/ambassador-bookings-staff-v1-staff-member/types';

export enum DateAvailabilityStatus {
  HAS_AVAILABLE_SLOTS = 'has_available_slots',
  HAS_UNAVAILABLE_SLOTS = 'has_slots',
}

export type SlotsMetaData = ListEventTimeSlotsResponse['pagingMetadata'];
export type DailyAgendaState = {
  selectedRange: { from: Date; to: Date };
  services: AsyncComponentData<Record<string, Service>>;
  locations: AsyncComponentData<Record<string, Location>>;
  staffMembers: AsyncComponentData<Record<string, StaffMember>>;
  selectedFilters: Filter[];
  selectedTimezone: string;
  selectedDate: Date;
  slots: AsyncComponentData<TimeSlot[]>;
  slotsMetaData: AsyncComponentData<SlotsMetaData>;
  activeFeatures: AsyncComponentData<any>; // what api to use for activeFeatures? AsyncComponentData<GetActiveFeaturesResponse>;
  availableDays: AsyncComponentData<string[]>;
  languageSettings: LanguageSettings;
};

export type ViewModelFactoryArgs = {
  state: DailyAgendaState;
  flowAPI: ControllerFlowAPI;
};

type LoadedAsyncComponentData<T> = {
  data: T;
  status: AsyncComponentDataStatus.IDLE;
};

type LoadingAsyncComponentData<T> = {
  status: AsyncComponentDataStatus.LOADING;
  data?: T;
};

type ErrorAsyncComponentData = {
  status: AsyncComponentDataStatus.ERROR;
  data?: never | unknown;
};

export type AsyncComponentData<T> =
  | LoadedAsyncComponentData<T>
  | LoadingAsyncComponentData<T>
  | ErrorAsyncComponentData;

export enum AsyncComponentDataStatus {
  LOADING = 'loading',
  ERROR = 'error',
  IDLE = 'idle',
}
