import { AsyncComponentDataStatus } from '../../state/types';
import { WidgetViewModelFactory } from '../viewModel';
import { formatLocalDateTimeToDateView } from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { createAgendaItemViewModel } from './agendaItemViewModel/agendaItemViewModel';
import { ViewModelSubscription } from '../initViewModelSubscriptions';

export type AgendaItemViewModel = {
  serviceName?: string;
  serviceImage?: { src: string; alt?: string };
  image?: any;
  startTime?: string;
  duration?: string;
  staffName?: string;
  staffImage?: string;
  location?: string;
  spots?: string;
  price?: string;
  buttonText?: string;
  disabled?: boolean;
  slotId: string;
};

export type DaySectionViewModel = {
  date: string;
  agendaItems: AgendaItemViewModel[];
};

export type AgendaViewModel = {
  days: DaySectionViewModel[];
};

export const createAgendaViewModel: WidgetViewModelFactory<
  AgendaViewModel | undefined
> = ({ state, flowAPI }) => {
  const { slots, services, staffMembers } = state;
  if (
    slots.status === AsyncComponentDataStatus.LOADING ||
    slots.status === AsyncComponentDataStatus.ERROR ||
    services.status === AsyncComponentDataStatus.LOADING ||
    services.status === AsyncComponentDataStatus.ERROR
  ) {
    return;
  }

  const timeSlots = slots.data;
  if (!timeSlots || timeSlots.length === 0) {
    return { days: [] };
  }

  const groupedSlotsByDays = new Map<string, AgendaItemViewModel[]>();
  timeSlots.forEach((slot) => {
    const service = services.data[slot.serviceId!];
    const staffMemberId = slot.availableResources?.[0]?.resources?.[0].id;
    const staffMember =
      staffMemberId && staffMembers.status === AsyncComponentDataStatus.IDLE
        ? staffMembers.data[staffMemberId]
        : undefined;
    const formattedDate = formatLocalDateTimeToDateView(slot.localStartDate!);
    const agendaItem = createAgendaItemViewModel({
      slot,
      state,
      flowAPI,
      service,
      staffMember,
    });

    if (groupedSlotsByDays.has(formattedDate)) {
      groupedSlotsByDays.get(formattedDate)?.push(agendaItem);
    } else {
      groupedSlotsByDays.set(formattedDate, [agendaItem]);
    }
  });
  const days = Array.from(groupedSlotsByDays.entries()).map(
    ([date, agendaItems]) => ({ date, agendaItems }),
  );
  return {
    days,
  };
};

export const subscribeToAgendaViewModel: ViewModelSubscription = ({
  subscribe,
  setViewModel,
  flowAPI,
  getState,
}) => {
  subscribe(
    ({ slots, services, staffMembers, slotsMetaData }) => ({
      slotsStatus: slots.status,
      servicesStatus: services.status,
      staffMembersStatus: staffMembers.status,
      slotsMetaDataStatus: slotsMetaData.status,
    }),
    () => {
      const agendaViewModel = createAgendaViewModel({
        state: getState(),
        flowAPI,
      });
      setViewModel({ agendaViewModel });
    },
  );
};
