import { listEventTimeSlots } from '../../api/listEventTimeSlots/listEventTimeSlots';
import { InitiatorsArgs } from '../initWidgetData';
import type { IHttpError } from '@wix/http-client';
import { AsyncComponentDataStatus } from '../../../components/DailyAgenda/state/types';

export const initSlots = async (args: InitiatorsArgs) => {
  const { flowAPI, setState } = args;
  try {
    const { timeSlots, pagingMetadata } = await listEventTimeSlots({
      flowAPI,
    });

    if (!timeSlots || timeSlots.length === 0) {
      setState({
        slots: {
          status: AsyncComponentDataStatus.ERROR,
          data: 'No slots found',
        },
      });
      return;
    }

    setState({
      slots: {
        status: AsyncComponentDataStatus.IDLE,
        data: timeSlots,
      },
      slotsMetaData: {
        status: AsyncComponentDataStatus.IDLE,
        data: pagingMetadata,
      },
    });
  } catch (error) {
    setState({
      slots: {
        status: AsyncComponentDataStatus.ERROR,
        data: (error as IHttpError).response?.data,
      },
      slotsMetaData: {
        status: AsyncComponentDataStatus.ERROR,
        data: (error as IHttpError).response?.data,
      },
    });
    console.error('Error initializing slots:', error);
  }
};
