import { getLocalDateFromDateTime } from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { listEventTimeSlots, QueryFilters } from '../../../../adapters';
import { convertSelectedFiltersToQueryFilters } from '../../../../adapters/api/queryFilters/queryFilters';
import { AsyncComponentDataStatus, DailyAgendaState } from '../../state/types';
import { DailyAgendaActionFactory, DailyAgendaActions } from '../actions';
import type { IHttpError } from '@wix/http-client';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import debounce from 'lodash.debounce';
import { Filter } from '../../../../types/types';

export const createOnFiltersChangeAction: DailyAgendaActionFactory<
  DailyAgendaActions['onFiltersChange']
> = ({ setState, flowAPI }) => {
  const onFiltersChange = async (newSelectedFilters: Filter[]) => {
    setState({
      selectedFilters: newSelectedFilters,
      selectedDate: undefined,
      slots: { status: AsyncComponentDataStatus.LOADING },
      availableDays: { status: AsyncComponentDataStatus.LOADING },
      slotsMetaData: { status: AsyncComponentDataStatus.LOADING },
    });

    const queryFilters =
      convertSelectedFiltersToQueryFilters(newSelectedFilters);

    try {
      const [{ timeSlots, pagingMetadata }, { timeSlots: availableDays }] =
        await fetchSlotsAndAvailableDays({
          filters: queryFilters,
          flowAPI,
        });
      const newState: Partial<DailyAgendaState> = {};

      newState.slots =
        !timeSlots || timeSlots.length === 0
          ? {
              status: AsyncComponentDataStatus.ERROR,
              data: 'No slots found',
            }
          : {
              status: AsyncComponentDataStatus.IDLE,
              data: timeSlots,
            };

      newState.availableDays =
        !availableDays || availableDays.length === 0
          ? {
              status: AsyncComponentDataStatus.ERROR,
              data: 'No available days found',
            }
          : {
              status: AsyncComponentDataStatus.IDLE,
              data: availableDays.map((slot) =>
                getLocalDateFromDateTime(new Date(slot.localStartDate!)),
              ),
            };

      newState.slotsMetaData = {
        status: AsyncComponentDataStatus.IDLE,
        data: pagingMetadata,
      };
      setState(newState);
    } catch (error) {
      setState({
        slots: {
          status: AsyncComponentDataStatus.ERROR,
          data: (error as IHttpError).response?.data,
        },
        availableDays: {
          status: AsyncComponentDataStatus.ERROR,
          data: (error as IHttpError).response?.data,
        },
        slotsMetaData: {
          status: AsyncComponentDataStatus.ERROR,
          data: (error as IHttpError).response?.data,
        },
      });
      console.error('Error fetching slots or availabiliy', error);
    }
  };

  const debouncedOnFiltersChange = debounce(
    async (newSelectedFilters: Filter[]) => {
      await onFiltersChange(newSelectedFilters);
    },
    250,
  );

  return (newSelectedFilters: Filter[]) =>
    debouncedOnFiltersChange(newSelectedFilters) as Promise<void>;
};

const fetchSlotsAndAvailableDays = async ({
  filters,
  flowAPI,
}: {
  filters: QueryFilters;
  flowAPI: ControllerFlowAPI;
}) =>
  Promise.all([
    listEventTimeSlots({
      flowAPI,
      filters,
    }),
    listEventTimeSlots({
      flowAPI,
      filters,
      timeSlotsPerDay: 1,
      limit: 7,
    }),
  ]);
