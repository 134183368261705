import { listEventTimeSlots as listEventTimeSlotsApi } from '@wix/ambassador-bookings-availability-v2-time-slot/http';
import { SlotsAvailability } from '../../../types/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { QueryFilters } from '../types';
import {
  getQueryFiltersFromSettings,
  getQueryFiltresIntersection,
} from '../queryFilters/queryFilters';
import {
  getLocalDateTimeEndOfDay,
  getLocalDateTimeStartOfDay,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import settingsParams from '../../../components/DailyAgenda/settingsParams';

export const listEventTimeSlots = async ({
  flowAPI,
  filters,
  timeSlotsPerDay,
  range = { from: new Date() },
  limit,
  cursor,
}: {
  limit?: number;
  cursor?: string;
  flowAPI: ControllerFlowAPI;
  filters?: QueryFilters;
  timeSlotsPerDay?: number;
  range?: { from: Date; to?: Date };
}) => {
  const { settings, httpClient } = flowAPI;

  const { serviceIds, businessLocationIds, staffMemberIds } =
    getQueryFiltresIntersection(getQueryFiltersFromSettings(settings), filters);

  const onlyAvailableSlots =
    settings.get(settingsParams.timeSlotsDisplay) ===
    SlotsAvailability.ONLY_AVAILABLE;
  const timeSlotsBeforeLoadMore = flowAPI.settings.get(
    settingsParams.timeSlotsBeforeLoadMore,
  );
  const response = await httpClient.request(
    listEventTimeSlotsApi({
      fromLocalDate: getLocalDateTimeStartOfDay(range.from),
      ...(range.to
        ? { toLocalDate: getLocalDateTimeEndOfDay(range.to) }
        : undefined),
      ...(serviceIds && serviceIds.length > 0 ? { serviceIds } : undefined),
      eventFilter: {
        type: 'CLASS',
        ...(staffMemberIds && staffMemberIds.length > 0
          ? { 'resources.id': staffMemberIds }
          : undefined),
        ...(businessLocationIds && businessLocationIds.length > 0
          ? { 'location.id': businessLocationIds }
          : undefined),
      },
      ...(timeSlotsPerDay ? { timeSlotsPerDay } : undefined),
      cursorPaging: { limit: limit || timeSlotsBeforeLoadMore, cursor },
      ...(onlyAvailableSlots ? { bookable: true } : undefined),
    }),
  );
  return response.data;
};
