import { AsyncComponentDataStatus } from '../../state/types';
import { DailyAgendaActionFactory, DailyAgendaActions } from '../actions';
import { listEventTimeSlots } from '../../../../adapters';
import type { IHttpError } from '@wix/http-client';

export const createOnLoadMoreAction: DailyAgendaActionFactory<
  DailyAgendaActions['onLoadMore']
> = ({ setState, getState, flowAPI }) => {
  const onLoadMoreAction: DailyAgendaActions['onLoadMore'] = async () => {
    const { slots, slotsMetaData } = getState();

    if (
      slots.status !== AsyncComponentDataStatus.IDLE ||
      slotsMetaData.status !== AsyncComponentDataStatus.IDLE ||
      !slotsMetaData.data?.hasNext
    ) {
      return;
    }

    const cursor = slotsMetaData.data?.cursors?.next!;

    setState({
      slotsMetaData: { status: AsyncComponentDataStatus.LOADING },
    });

    try {
      const { timeSlots, pagingMetadata } = await listEventTimeSlots({
        flowAPI,
        cursor,
      });

      if (!timeSlots || timeSlots.length === 0) {
        setState({
          slotsMetaData: {
            status: AsyncComponentDataStatus.ERROR,
            data: 'No more slots found',
          },
        });
        return;
      }

      setState({
        slots: {
          status: AsyncComponentDataStatus.IDLE,
          data: [...(slots.data || []), ...timeSlots],
        },
        slotsMetaData: {
          status: AsyncComponentDataStatus.IDLE,
          data: pagingMetadata,
        },
      });
    } catch (error) {
      setState({
        slotsMetaData: {
          status: AsyncComponentDataStatus.ERROR,
          data: (error as IHttpError).response?.data,
        },
      });
      console.error('Error loading more slots', error);
    }
  };

  return onLoadMoreAction;
};
