import { createStore } from 'zustand/vanilla';
import { DailyAgendaState } from './types';
import { subscribeWithSelector } from 'zustand/middleware';

export type StateUpdater = (
  prevState: DailyAgendaState,
) => Partial<DailyAgendaState>;

export type SetState = (
  state: Partial<DailyAgendaState> | StateUpdater,
) => void;

export type GetState = () => DailyAgendaState;

export type Subscribe<T = DailyAgendaState> = {
  (listener: (selectedState: T, previousSelectedState: T) => void): () => void;
  <U>(
    selector: (state: T) => U,
    listener: (selectedState: U, previousSelectedState: U) => void,
    options?: {
      equalityFn?: (a: U, b: U) => boolean;
      fireImmediately?: boolean;
    },
  ): () => void;
};

export const createState = (
  initialState: DailyAgendaState,
): {
  setState: SetState;
  getState: GetState;
  subscribe: Subscribe<DailyAgendaState>;
} => {
  const state = createStore<DailyAgendaState>()(
    subscribeWithSelector(() => initialState),
  );

  return state;
};
