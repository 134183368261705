import {
  getLocalDateFromDateTime,
  getFirstDayOfTheWeek,
} from '@wix/bookings-catalog-calendar-viewer-utils/dateAndTime';
import { WidgetViewModelFactory } from '../viewModel';
import { AsyncComponentDataStatus } from '../../state/types';
import { ViewModelSubscription } from '../initViewModelSubscriptions';

export type DatePickerViewModel = {
  selectedDate?: string;
  availableDates: string[];
  locale: string;
  today: string;
  firstDayOfTheWeek: number;
  accessibility: {
    nextAriaLabel: string;
    prevAriaLabel: string;
  };
};

export const createDatePickerViewModel: WidgetViewModelFactory<
  DatePickerViewModel
> = ({
  state: { selectedDate, languageSettings, availableDays },
  flowAPI: {
    translations: { t },
  },
}) => {
  const today = new Date().toISOString().split('T')[0];
  const firstDayOfTheWeek = getFirstDayOfTheWeek(
    languageSettings.dateRegionalSettingsLocale,
  );

  const accessibility = {
    nextAriaLabel: t('app.week-picker.accessibility.next-week'),
    prevAriaLabel: t('app.week-picker.accessibility.prev-week'),
  };

  return {
    selectedDate: getLocalDateFromDateTime(selectedDate),
    locale: languageSettings.dateRegionalSettingsLocale,
    availableDates:
      availableDays.status === AsyncComponentDataStatus.IDLE
        ? availableDays.data
        : [],
    today,
    firstDayOfTheWeek,
    accessibility,
  };
};

export const subscribeToDatePickerViewModel: ViewModelSubscription = ({
  subscribe,
  setViewModel,
  flowAPI,
  getState,
}) =>
  subscribe(
    ({ selectedDate, languageSettings, availableDays }) => ({
      selectedDate,
      languageSettings,
      availableDays,
    }),
    ({ selectedDate, availableDays }) => {
      const datePickerViewModel = createDatePickerViewModel({
        flowAPI,
        state: { ...getState(), selectedDate, availableDays },
      });
      setViewModel({
        datePickerViewModel,
      });
    },
  );
